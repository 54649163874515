<template>
  <div class="vswiper">
    <swiper
      class="mySwiper"
      :loop="true"
      :slidesPerView="2"
      :spaceBetween="30"
      :slidesPerGroup="1"
      :autoplay="autoplayOptions"
      :modules="modules"
      :pagination="true"
    >
      <swiper-slide v-for="(item, index) in swiperdata" :key="index">
        <img :src="item.vupCover" alt="" />
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { Pagination, Navigation, Autoplay } from "swiper";
export default {
  props: ["swiperdata"],
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const autoplayOptions = ref({
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      loopAdditionalSlides: 0,
      // reverseDirection: true,
    });
    return {
      autoplayOptions,
      modules: [Pagination, Navigation, Autoplay],
    };
  },
};
</script>
<style lang="scss" scoped>
.vswiper {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px 10px;
  box-shadow: 5px 5px 10px 5px #ccc inset;
}
.swiper {
  width: 100%;
  height: 350px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: #fff;
  // opacity: 0.9;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: none;
}
</style>

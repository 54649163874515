<template>
  <div class="vup animated fadeIn">
    <!-- <a-tabs
      v-model:activeKey="activeKey"
      centered
      :tabBarStyle="{
        background: '#f78fad',
        color: 'white',
      }"
    >
      <a-tab-pane key="1" tab="VUP"><Vuppage /></a-tab-pane>
      <a-tab-pane key="2" tab="同人画集" force-render>
        <Album />
      </a-tab-pane>
    </a-tabs> -->
    <Vuppage />
  </div>
</template>
<script>
import { ref } from "vue";
// import Album from "./pages/album.vue";
import Vuppage from "./pages/vuppage.vue";
export default {
  components: {
    // Album,
    Vuppage,
  },
  setup() {
    const activeKey = ref("1");
    return {
      activeKey,
    };
  },
};
</script>
<style lang="scss" scoped>
.vup {
  .ant-tabs-nav {
    background: #cc99ff;
  }
}
</style>

<template>
  <div class="vuppage">
    <!-- <Vupswiper /> -->
    <VswiperVue :swiperdata="swiperdata" />
    <div class="vuparea">
      <div v-for="item in vuparr" :key="item.vupid" class="vupbox mt5 mr5">
        <Cardpage :item="item" @click="tojump(item)" />
      </div>
    </div>
    <!-- <div class="mt5 pagination">
      <a-pagination
        v-model:current="pageOption.pageNum"
        :total="pageOption.total"
        :defaultPageSize="pageOption.pageSize"
        :showSizeChanger="false"
        show-less-items
        @change="onChangepage"
      />
    </div> -->
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue";
// import Vupswiper from "./components/swiper.vue";
import Cardpage from "../../../components/cardcom/index.vue";

import { vuplist } from "../../../api/vup/vup";
// import { useRouter } from "vue-router";
import VswiperVue from "../../../components/swiper/vswiper.vue";
export default {
  components: {
    // Vupswiper,
    Cardpage,
    VswiperVue,
  },
  setup() {
    // const router = useRouter();
    const status = reactive({
      vuparr: [],
      pageOption: {
        pageSize: "8",
        pageNum: "1",
        total: 0,
      },
      swiperdata: [
        {
          pic: "https://assets.floydzero.com/vupimg/swiperimg/bm.jpg",
          bannerId: 1,
        },
        {
          pic: "https://assets.floydzero.com/vupimg/swiperimg/fls1.jpg",
          bannerId: 2,
        },
        {
          pic: "https://assets.floydzero.com/vupimg/swiperimg/lzbg.jpg",
          bannerId: 3,
        },
        {
          pic: "https://assets.floydzero.com/vupimg/swiperimg/tao1.jpg",
          bannerId: 4,
        },
      ],
    });
    onMounted(() => {
      getvupList();
      window.addEventListener("scroll", debounce, true);
    });
    async function getvupList() {
      let { code, data } = await vuplist({
        pageSize: status.pageOption.pageSize,
        pageNum: status.pageOption.pageNum,
      });
      if (code === 200) {
        status.vuparr = status.vuparr.concat(data.list);
        status.pageOption.total = data.total;
        status.swiperdata = data.list;
      }
    }
    function tojump(item) {
      localStorage.setItem("vupdetail", JSON.stringify(item));
      // router.push({ path: "/vup/vupdetail", query: { detailid: item.vupid } });
      // router.push("/vup/vupdetail");
    }
    function onChangepage(page) {
      status.pageOption.pageNum = page;
      getvupList();
    }
    function handlescroll() {
      let scrolltop = document.querySelector(".ant-layout-content").scrollTop;
      let scrolltop2 = document.querySelector(
        ".ant-layout-content"
      ).clientHeight;
      let scrolltop3 = document.querySelector(
        ".ant-layout-content"
      ).scrollHeight;
      if (
        scrolltop3 - scrolltop2 - scrolltop < 100 &&
        status.pageOption.pageNum < Math.floor(status.pageOption.total / 8)
      ) {
        status.pageOption.pageNum++;
        getvupList();
      }

      // console.log(v);
    }
    function debounce() {
      let timer = null;

      if (this.timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        handlescroll();
      }, 500);
    }
    return {
      ...toRefs(status),
      tojump,
      onChangepage,
      handlescroll,
      debounce,
    };
  },
};
</script>
<style scoped>
::v-deep .ant-card-cover {
  height: 170px;
}
</style>
<style lang="scss" scoped>
.vuppage {
  // overflow-y: auto;
  .vuparea {
    display: flex;
    flex-wrap: wrap;

    .vupbox {
      width: calc(100% / 4.1);
      // height: 200px;
      // display: flex;
      // justify-content: space-around;
    }
  }
  .pagination {
    text-align: right;
  }
}
</style>

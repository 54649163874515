<template>
  <div class="cardcom">
    <div class="wrapper">
      <div class="col" ontouchstart="this.classList.toggle('hover');">
        <div class="container">
          <div
            class="front"
            :style="{
              background: 'url(' + item.vupCover + ') center no-repeat',
              backgroundSize: '400px',
            }"
          >
            <div class="inner">
              <p>{{ item.vupName }}</p>
              <!-- <span>Lorem ipsum</span> -->
              <span>
                <a-avatar :src="item.vupFace" :size="64" />
              </span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>
                {{ item.vupSign }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  setup() {
    // console.log(props);

    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
.cardcom {
}
</style>
